import React from 'react';
import '../../components/loading/css/LoadingAnimation.css'
import logo from "../../images/assets/Logo-01.webp";

const LoadingAnimation = () => {
  return (
    <div className="loading-animation-container">
      <div className="loading-animation"></div>
      <img src={logo} alt="Company Logo" className="company-logo" />
    </div>
  );
};

export default LoadingAnimation;