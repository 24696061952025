import  { useEffect } from 'react';

import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { GoogleTagManagerID, GoogleAnalyticsID, FacebookPixelID } from '../constants';
import { useLocation } from 'react-router-dom';

const Analytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Función para cargar Google Tag Manager
    const loadTagManager = () => {
      TagManager.initialize(GoogleTagManagerID);
      window.dataLayer.push({ event: 'pageview' });
    };

    // Función para cargar Google Analytics
    const loadGoogleAnalytics = () => {
      ReactGA.initialize(GoogleAnalyticsID);
      ReactGA.pageview(location.pathname + location.search);
  
    };

    // Función para cargar Facebook Pixel
    const loadFacebookPixel = () => {
      ReactPixel.init(FacebookPixelID, {}, { debug: true, autoConfig: false });
      ReactPixel.pageView();
      ReactPixel.fbq('track', 'PageView');
    };

    // Cargar los scripts según sea necesario
    if (GoogleTagManagerID) {
      loadTagManager();
    }

    if (GoogleAnalyticsID) {
      loadGoogleAnalytics();
    }

    if (FacebookPixelID) {
      loadFacebookPixel();
    }
  }, [location]);

  return null;
};

export default Analytics;
